// Base icon styling
.b-icon {
  &.bi {
    display: inline-block;
    overflow: visible;
    // The following is required to compensate for alignment
    // issues with the Bootstrap Icons alpha implementation
    // TODO: Is this "hack" still required?
    vertical-align: $b-icon-vertical-offset;
  }

  &.b-icon-animation-spin,
  &.b-iconstack .b-icon-animation-spin > g {
    transform-origin: center;
    animation: $b-icon-animation-spin-duration infinite linear normal b-icon-animation-spin;

    @media (prefers-reduced-motion: reduce) {
      animation: none;
    }
  }

  &.b-icon-animation-spin-reverse,
  &.b-iconstack .b-icon-animation-spin-reverse > g {
    transform-origin: center;
    animation: $b-icon-animation-spin-reverse-duration infinite linear reverse b-icon-animation-spin;

    @media (prefers-reduced-motion: reduce) {
      animation: none;
    }
  }

  &.b-icon-animation-spin-pulse,
  &.b-iconstack .b-icon-animation-spin-pulse > g {
    transform-origin: center;
    animation: $b-icon-animation-spin-pulse-duration infinite steps(8) normal b-icon-animation-spin;

    @media (prefers-reduced-motion: reduce) {
      animation: none;
    }
  }

  &.b-icon-animation-spin-reverse-pulse,
  &.b-iconstack .b-icon-animation-spin-reverse-pulse > g {
    transform-origin: center;
    animation: $b-icon-animation-spin-reverse-pulse-duration infinite steps(8) reverse
      b-icon-animation-spin;

    @media (prefers-reduced-motion: reduce) {
      animation: none;
    }
  }

  &.b-icon-animation-cylon,
  &.b-iconstack .b-icon-animation-cylon > g {
    transform-origin: center;
    animation: $b-icon-animation-cylon-duration infinite ease-in-out alternate
      b-icon-animation-cylon;

    @media (prefers-reduced-motion: reduce) {
      animation: none;
    }
  }

  &.b-icon-animation-cylon-vertical,
  &.b-iconstack .b-icon-animation-cylon-vertical > g {
    transform-origin: center;
    animation: $b-icon-animation-cylon-vertical-duration infinite ease-in-out alternate
      b-icon-animation-cylon-vertical;

    @media (prefers-reduced-motion: reduce) {
      animation: none;
    }
  }
}

// Animation for spinning icons
@keyframes b-icon-animation-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}

@keyframes b-icon-animation-cylon {
  0% {
    transform: translateX(-25%);
  }
  100% {
    transform: translateX(25%);
  }
}

@keyframes b-icon-animation-cylon-vertical {
  0% {
    transform: translateY(25%);
  }
  100% {
    transform: translateY(-25%);
  }
}

// Make icons slightly larger in buttons, nav-links, dropdowns, and input-group-text
.btn,
.nav-link,
.dropdown-toggle,
.dropdown-item,
.input-group-text {
  .b-icon {
    &.bi {
      font-size: 125%;
      vertical-align: text-bottom;
    }
  }
}
