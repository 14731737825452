.b-form-spinbutton.form-control {
  text-align: center;
  // Quick way to get end buttons rounded on outside edges
  overflow: hidden;
  // Hide validation icon, as there is no room for it
  background-image: none;

  &.flex-column {
    height: auto;
    width: auto;
  }

  @at-root {
    // Prevent the buttons from reversing order on in horizontal RTL mode
    [dir="rtl"] &:not(.flex-column),
    &[dir="rtl"]:not(.flex-column) {
      flex-direction: row-reverse;
    }
  }

  output {
    font-size: inherit;
    outline: 0;
    border: 0;
    background-color: transparent;

    > div,
    > bdi {
      display: block;
      min-width: 2.25em;
      // We set a height to ensure an empty value isn't collapsed
      height: 1em * $input-line-height;
    }
  }

  &.d-inline-flex:not(.flex-column) {
    &,
    output {
      width: auto;
    }
  }

  .btn {
    line-height: 1;
    box-shadow: none !important;

    &:disabled {
      pointer-events: none;
    }

    &:hover:not(:disabled) > div {
      transform: scale(1.25);
    }
  }

  &.disabled,
  &.readonly {
    background-color: $input-disabled-bg;
  }

  &.disabled {
    pointer-events: none;
  }
}
